import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Gallery: React.FC = () => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const { width } = useWindowSize(); // Get the current window width

  useEffect(() => {
    if (selectedImg) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [selectedImg]);

  // Number of images
  const numImages = 6;

  const imageVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 1,
        bounce: 0.3,
      },
    },
  };

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  // Define a breakpoint for desktop
  const desktopBreakpoint = 1024; // You can adjust this value as needed

  return (
    <section className="flex flex-col w-full justify-center items-center">
      <div className="text-left sticky bg-primary text-secondary my-14 w-[95%]">
        <h2 className="text-[3rem] sm:text-[4rem] font-bold tracking-tight pr-10 w-full">
          Our Work
        </h2>
      </div>

      <div className="flex justify-center items-center max-w-full">
        <div className="flex flex-col justify-center items-center gap-4 w-full">
          {/* Conditional rendering based on screen width */}
          {width < desktopBreakpoint ? (
            <ColumnLayout
              numImages={numImages}
              setSelectedImg={setSelectedImg}
              imageVariants={imageVariants}
            />
          ) : (
            <GridLayout
              numImages={numImages}
              setSelectedImg={setSelectedImg}
              imageVariants={imageVariants}
            />
          )}
        </div>
      </div>

      {selectedImg && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={() => setSelectedImg(null)}
          initial="hidden"
          animate="visible"
          variants={modalVariants}
        >
          <motion.img
            src={selectedImg}
            alt="Enlarged work promo"
            className="max-w-3/4 max-h-3/4"
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          />
        </motion.div>
      )}
    </section>
  );
};

// Separate component for column layout (mobile and tablet views)
const ColumnLayout: React.FC<{
  numImages: number;
  setSelectedImg: (img: string | null) => void;
  imageVariants: any;
}> = ({ numImages, setSelectedImg, imageVariants }) => (
  <div className="flex flex-col justify-center items-center gap-4 w-full">
    {Array.from({ length: numImages }).map((_, index) => (
      <AnimatedImage
        key={index}
        src={`/imgs/WORK${index + 1}.jpg`}
        alt={`WORK${index + 1}`}
        onClick={() => setSelectedImg(`/imgs/WORK${index + 1}.jpg`)}
        variants={imageVariants}
      />
    ))}
  </div>
);

// Separate component for grid layout (desktop views)
const GridLayout: React.FC<{
  numImages: number;
  setSelectedImg: (img: string | null) => void;
  imageVariants: any;
}> = ({ numImages, setSelectedImg, imageVariants }) => (
  <div className="grid grid-cols-3 gap-4">
    {Array.from({ length: numImages }).map((_, index) => (
      <AnimatedImage
        key={index}
        src={`/imgs/WORK${index + 1}.jpg`}
        alt={`WORK${index + 1}`}
        onClick={() => setSelectedImg(`/imgs/WORK${index + 1}.jpg`)}
        variants={imageVariants}
      />
    ))}
  </div>
);

interface AnimatedImageProps {
  src: string;
  alt: string;
  onClick: () => void;
  variants: any;
}

const AnimatedImage: React.FC<AnimatedImageProps> = ({
  src,
  alt,
  onClick,
  variants,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.img
      ref={ref}
      src={src}
      alt={alt}
      className="w-full my-2 cursor-pointer px-6"
      onClick={onClick}
      initial="hidden"
      animate={controls}
      variants={variants}
    />
  );
};

// Custom hook to get the window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default Gallery;
